import React from 'react';
import { footerSection } from '../../mock/mock';

const Footer = () => {
	return (
		<div className='bg-black p-4 text-sm text-white text-center'>
			{footerSection.COPYRIGHT_TEXT}
		</div>
	);
};

export default Footer;
