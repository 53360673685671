import React, { useState } from 'react';
import Slider from 'react-slick';
import Modal from 'react-modal';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { GallerySection, gallerySliderImages } from '../../mock/mock';
import Title from '../title';

const Gallery = () => {
	const [modalOpen, setModalOpen] = useState(false);
	const [selectedImage, setSelectedImage] = useState(0);

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 5,
		slidesToScroll: 1,
		arrows: true,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: true,
					dots: true,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					initialSlide: 2,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	const openModal = (index) => {
		setSelectedImage(index);
		setModalOpen(true);
	};

	const closeModal = () => {
		setModalOpen(false);
	};

	const goToPrevious = () => {
		setSelectedImage(
			(selectedImage + gallerySliderImages.length - 1) %
				gallerySliderImages.length
		);
	};

	const goToNext = () => {
		setSelectedImage((selectedImage + 1) % gallerySliderImages.length);
	};

	return (
		<div
			id='gallery'
			className='my-20'
		>
			<Title
				className='mb-18'
				cursiveTitle={GallerySection.CURSIVE_TITLE}
				mainTitle={GallerySection.MAIN_TITLE}
				description={GallerySection.DESCRIPTION}
			/>
			<Slider {...settings}>
				{gallerySliderImages.map((image, index) => (
					<div
						className='carousel-item px-1'
						key={index}
					>
						<img
							src={image.src}
							alt={image.alt}
							onClick={() => openModal(index)}
						/>
					</div>
				))}
			</Slider>

			<Modal
				isOpen={modalOpen}
				onRequestClose={closeModal}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.75)',
						zIndex: 2,
					},
					content: {
						position: 'absolute',
						inset: 'auto',
						border: 'none',
						background: 'transparent',
						overflow: 'auto',
						WebkitOverflowScrolling: 'touch',

						outline: 'none',
						padding: '0',
					},
				}}
			>
				<div className='w-screen h-screen flex items-center justify-center'>
					<img
						src={gallerySliderImages[selectedImage].src}
						alt={gallerySliderImages[selectedImage].alt}
					/>
				</div>
				<button
					className='prev'
					onClick={goToPrevious}
				>
					<svg
						width='50px'
						height='50px'
						viewBox='0 0 24 24'
						id='_24x24_On_Light_Previous'
						data-name='24x24/On Light/Previous'
						xmlns='http://www.w3.org/2000/svg'
					>
						<rect
							id='view-box'
							width='24'
							height='24'
							fill='#141124'
							opacity='0'
						/>
						<path
							id='Shape'
							d='M4.22,9.28l-4-4L.207,5.267.2,5.261.194,5.254.187,5.246.182,5.24.174,5.231l0,0-.008-.01,0,0L.151,5.2l0,0L.14,5.186l0,0L.13,5.172l0,0L.12,5.157l0-.006L.111,5.142l0-.007,0-.008L.1,5.118l0-.006L.087,5.1l0,0A.751.751,0,0,1,.235,4.2L4.22.22A.75.75,0,0,1,5.28,1.281L2.561,4H14.75a.75.75,0,0,1,0,1.5H2.561L5.28,8.22A.75.75,0,1,1,4.22,9.28Z'
							transform='translate(4.25 7.25)'
							fill='#ffffff'
						/>
					</svg>
				</button>
				<button
					className='next'
					onClick={goToNext}
				>
					<svg
						width='50px'
						height='50px'
						viewBox='0 0 24 24'
						id='_24x24_On_Light_Next'
						data-name='24x24/On Light/Next'
						xmlns='http://www.w3.org/2000/svg'
					>
						<rect
							id='view-box'
							width='24'
							height='24'
							fill='#141124'
							opacity='0'
						/>
						<path
							id='Shape'
							d='M10.22,9.28a.75.75,0,0,1,0-1.06l2.72-2.72H.75A.75.75,0,0,1,.75,4H12.938L10.22,1.281A.75.75,0,1,1,11.281.22l4,4a.749.749,0,0,1,0,1.06l-4,4a.75.75,0,0,1-1.061,0Z'
							transform='translate(4.25 7.25)'
							fill='#ffffff'
						/>
					</svg>
				</button>

				<button
					className='absolute top-5 right-5'
					onClick={() => {
						closeModal();
					}}
				>
					<svg
						width='32px'
						height='32px'
						viewBox='0 0 24 24'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							fill-rule='evenodd'
							clip-rule='evenodd'
							d='M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z'
							fill='#ffffff'
						/>
					</svg>
				</button>
			</Modal>
		</div>
	);
};

export default Gallery;
