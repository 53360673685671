import React from 'react';
import { Link } from 'react-scroll';
import { bannerSection } from '../../mock/mock.js';

const Banner = () => {
	return (
		<div
			className='relative z-0 h-screen xl:min-h-[600px] bg-no-repeat bg-cover'
			style={{ backgroundImage: `url(${bannerSection.BANNER_IMAGE})` }}
		>
			<div className='absolute inset-0 bg-black/[0.5]'></div>

			<div className='absolute inset-0 xl:top-33 z-10 flex flex-col items-center justify-center text-white px-5 md:px-0'>
				<h1 className='font-cursive text-2xl xl:text-4xl '>
					{bannerSection.WELCOME_TEXT}
				</h1>
				<h2 className='font-serif text-[40px] lg:text-3xl xl:text-5xl max-w-bannerTitleWidth leading-1 xl:leading-bannerTitle text-center mb-5 '>
					{bannerSection.MAIN_TITLE}
				</h2>
				<p className='text-lg text-center mb-18'>{bannerSection.SLOGAN_TEXT}</p>

				<Link
					to='starter'
					spy={true}
					smooth={true}
					offset={-120}
					duration={500}
					className='btn btn-primary cursor-pointer'
				>
					View Menu
				</Link>
			</div>
		</div>
	);
};

export default Banner;
