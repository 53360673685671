import React from 'react';
import Title from '../title';
import { CateringSection } from '../../mock/mock';

const Catering = () => {
	return (
		<>
			<Title
				className='mb-18'
				cursiveTitle={CateringSection.CURSIVE_TITLE}
				mainTitle={CateringSection.MAIN_TITLE}
				description={CateringSection.DESCRIPTION}
				notice={CateringSection.NOTICE}
			/>

			<table className='table table-stripe'>
				<thead>
					<tr>
						<th>Foods</th>
						<th>
							Small Tray <div className='text-sm'>(10 people)</div>
						</th>
						<th>
							Large Tray <div className='text-sm'>(20 people)</div>
						</th>
					</tr>
				</thead>

				<tbody>
					<tr>
						<td>Any Chicken</td>
						<td>$80.00</td>
						<td>$130.00</td>
					</tr>
					<tr>
						<td>Any Vegeterian</td>
						<td>$70.00</td>
						<td>$130.00</td>
					</tr>
					<tr>
						<td>Any Lamb</td>
						<td>$100.00</td>
						<td>$170.00</td>
					</tr>
					<tr>
						<td>Vegetable Samosa</td>
						<td>$60.00</td>
						<td>$110.00</td>
					</tr>
					<tr>
						<td>Chicken Samosa</td>
						<td>$70.00</td>
						<td>$130.00</td>
					</tr>
					<tr>
						<td>Naan Bread</td>
						<td>$45.00</td>
						<td>$85.00</td>
					</tr>
				</tbody>
			</table>

			<div className='bg-gray-50  p-4 my-10 md:w-[50%] mx-auto rounded-sm font-bold border-2 border-dashed border-red-200 text-center'>
				{CateringSection.NOTE}
			</div>
		</>
	);
};

export default Catering;
