import React from 'react';
import Title from '../title';

import { specialMenuSection } from '../../mock/mock';

const SpecialMenu = () => {
	const specialMenuList = specialMenuSection.SPECIAL_MENU_ITEMS;
	return (
		<div className='my-20'>
			<Title
				className='mb-10'
				cursiveTitle={specialMenuSection.CURSIVE_TITLE}
				mainTitle={specialMenuSection.MAIN_TITLE}
				price={specialMenuSection.PRICE}
				description={specialMenuSection.DESCRIPTION}
				subTitle={specialMenuSection.SUBTITLE}
			/>

			<div className='container px-3 md:px-8 text-center'>
				<div className='md:w-[50%] mx-auto'>
					{specialMenuList.map((menuItem, i) => {
						return (
							<div
								key={i}
								className=' '
							>
								<h3 className='text-2xl text-center font-serif '>
									{menuItem.TITLE}
								</h3>

								{menuItem.SUBCATEGORIES &&
									menuItem.SUBCATEGORIES.map((item) => {
										return (
											<>
												<div className='mb-4'>
													<h4 className='text-xl font-serif '>
														{item.LABEL}{' '}
														<span className='text-sm font-bold bg-gray-100 py-1 px-2 inline-block font-sans'>
															{item.EXTRA}
														</span>
													</h4>

													<p className='text-grey'>{item.ITEMS}</p>
												</div>
											</>
										);
									})}

								<p className='text-grey'>{menuItem.ITEMS}</p>

								<hr className='m-5' />
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default SpecialMenu;
