import React from 'react';
import logo from '../../images/logo.png';
const Logo = () => {
	return (
		<a href='/#'>
			<img
				width={150}
				src={logo}
				alt='restaurant'
			/>
		</a>
	);
};

export default Logo;
