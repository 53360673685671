import React, { useEffect, useState } from 'react';
import TopHeader from './TopHeader';
import Logo from './Logo';
import Navigation from './Navigation';

const Header = () => {
	const [isSticky, setIsSticky] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 150) {
				setIsSticky(true);
			} else {
				setIsSticky(false);
			}
		};

		window.addEventListener('scroll', handleScroll);
	}, []);

	return (
		<div className='absolute top-0 z-10 w-full'>
			<TopHeader className='hidden md:block' />

			<div className='pt-4'>
				<header className={`${isSticky ? 'is-sticky' : ''}`}>
					<div className='container'>
						<div className='flex justify-between items-center py-3'>
							<Logo />
							<Navigation />
						</div>
					</div>
				</header>
			</div>
		</div>
	);
};

export default Header;
