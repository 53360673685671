import React from 'react';
import Title from '../title';
import { storySection } from '../../mock/mock';

const Story = () => {
	return (
		<>
			<div className='my-20'>
				<Title
					className='mb-18'
					cursiveTitle={storySection.CURSIVE_TITLE}
					mainTitle={storySection.MAIN_TITLE}
					description={storySection.DESCRIPTION}
				/>

				<div className='container px-3 md:px-8'>
					<div className='flex flex-wrap'>
						<div className='md:w-1/2 md:pr-4 mb-4 md:mb-0'>
							<img
								className='w-full'
								src={storySection.IMAGE1}
								alt=''
							/>
						</div>
						<div className='md:w-1/2 md:pl-4'>
							<img
								className='w-full'
								src={storySection.IMAGE2}
								alt=''
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Story;
