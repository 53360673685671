import React from 'react';
import leafIcon from '../../images/leaf-icon.png';
import { clsx } from 'clsx';

const Title = (props) => {
	return (
		<>
			<div
				className={clsx(
					props.className,
					'text-center max-w-titleSection mx-auto px-5 md:px-0'
				)}
			>
				<h2 className='font-cursive text-3xl leading-1 text-primary'>
					{props.cursiveTitle}
				</h2>
				<h2 className='font-serif text-3xl leading-1'>
					{props.mainTitle} {props.price && `(${props.price})`}
				</h2>
				{props.subTitle && (
					<p className='text-lg font-bold bg-gray-100 py-2 px-8 inline-block mt-3'>
						{props.subTitle}
					</p>
				)}
				<img
					className='mx-auto my-4'
					src={leafIcon}
					alt='leaf Icon'
				/>
				<p>{props.description}</p>
				{props.notice && <p className='font-bold'>{props.notice}</p>}
			</div>
		</>
	);
};

export default Title;
