import React from 'react';

import Header from './components/header';
import Footer from './components/footer';

import { siteHeaders } from './mock/mock';
import { Helmet } from 'react-helmet';
import { BrowserRouter } from 'react-router-dom';
import Router from './routes';

const App = () => {
	return (
		<>
			<BrowserRouter>
				<Helmet>
					<meta charSet='utf-8' />
					<title>{siteHeaders.title}</title>
				</Helmet>
				<Header />
				<Router />

				<Footer />
			</BrowserRouter>
		</>
	);
};

export default App;
