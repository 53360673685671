import React from 'react';
import { MdCall, MdLocationOn, MdOutlineEmail } from 'react-icons/md';

import {contactDetails} from '../../mock/mock';

const TopHeader = ({ className }) => {
	return (
		<div
			className={`${className} border-b border-white/[0.30] text-white py-4`}
		>
			<div className='container'>
				<div className='flex justify-between'>
					<div className='flex items-center'>
						<MdLocationOn className='mr-2' />
						<span className='text-sm'>
							{contactDetails.address}
						</span>
					</div>

					<div className='flex items-center gap-16'>
						<div className='flex items-center'>
							<MdCall className='mr-2' />
							<span className='text-sm'>
								<a href={`tel:+${contactDetails.contactNo}`}>+{contactDetails.contactNo}</a>
							</span>
						</div>

						<div className='flex items-center'>
							<MdOutlineEmail className='mr-2' />
							<span className='text-sm'>
								<a href={`mailto:${contactDetails.email}`}>
									{contactDetails.email}
								</a>
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TopHeader;
