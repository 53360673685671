import React, { useState } from 'react';
import { Link } from 'react-scroll';

const Navigation = () => {
	const [navbar, setNavbar] = useState(false);

	const closeMenu = () => {
		setNavbar(false);
	};

	return (
		<>
			<div className={`menu md:block ${navbar ? 'block' : 'hidden'}`}>
				<ul className='md:flex md:gap-8'>
					<li>
						<Link
							activeClass='active'
							to='/banner'
							spy={true}
							smooth={true}
							offset={-120}
							duration={500}
							className='text-white cursor-pointer font-medium text-sm'
							onClick={closeMenu}
						>
							HOME
						</Link>
					</li>
					<li>
						<Link
							activeClass='active'
							to='story'
							spy={true}
							smooth={true}
							offset={-120}
							duration={500}
							className='text-white cursor-pointer font-medium text-sm'
							onClick={closeMenu}
						>
							STORY
						</Link>
					</li>
					<li>
						<Link
							activeClass='active'
							to='starter'
							spy={true}
							smooth={true}
							offset={-120}
							duration={500}
							className='text-white cursor-pointer font-medium text-sm'
							onClick={closeMenu}
						>
							STARTER
						</Link>
					</li>
					<li>
						<Link
							activeClass='active'
							to='special'
							spy={true}
							smooth={true}
							offset={-120}
							duration={500}
							className='text-white cursor-pointer font-medium text-sm'
							onClick={closeMenu}
						>
							SPECIAL
						</Link>
					</li>
					<li>
						<Link
							activeClass='active'
							to='gallery'
							spy={true}
							smooth={true}
							offset={-120}
							duration={500}
							className='text-white cursor-pointer font-medium text-sm'
							onClick={closeMenu}
						>
							GALLERY
						</Link>
					</li>
					<li>
						<Link
							activeClass='active'
							to='contact'
							spy={true}
							smooth={true}
							offset={-120}
							duration={500}
							className='text-white cursor-pointer font-medium text-sm'
							onClick={closeMenu}
						>
							CONTACT US
						</Link>
					</li>
				</ul>
			</div>

			<div className='md:hidden'>
				<button
					className='text-white focus:outline-none'
					onClick={() => setNavbar(!navbar)}
				>
					{navbar ? (
						<svg
							xmlns='http://www.w3.org/2000/svg'
							className='w-6 h-6'
							viewBox='0 0 20 20'
							fill='currentColor'
						>
							<path
								fillRule='evenodd'
								d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
								clipRule='evenodd'
							/>
						</svg>
					) : (
						<svg
							xmlns='http://www.w3.org/2000/svg'
							className='w-6 h-6'
							fill='none'
							viewBox='0 0 24 24'
							stroke='currentColor'
							strokeWidth={2}
						>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								d='M4 6h16M4 12h16M4 18h16'
							/>
						</svg>
					)}
				</button>
			</div>
		</>
	);
};

export default Navigation;
