import React from 'react';
import Title from '../title';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { APPETIZER_ITEMS, starterSection } from '../../mock/mock';
import { Link } from 'react-router-dom';
import Food from './Food';

const Starter = () => {
	const tabList = starterSection.TABLIST;
	const starterMenuList = APPETIZER_ITEMS;
	const mainCourseMenuList = starterSection.MAIN_COURSE_MENU_ITEMS;
	const breverageMenuList = starterSection.BREVERAGE_MENU_ITEMS;

	return (
		<div className='my-20'>
			<Title
				className='mb-18'
				cursiveTitle={starterSection.CURSIVE_TITLE}
				mainTitle={starterSection.MAIN_TITLE}
				description={starterSection.DESCRIPTION}
			/>

			<div className='container px-3 md:px-8'>
				<Tabs>
					<TabList className='flex flex-wrap justify-center gap-1 md:gap-5 mb-18'>
						{tabList.map((item, i) => {
							return (
								<Tab className='btn btn-tab bg-black text-white cursor-pointer'>
									{tabList[i]}
								</Tab>
							);
						})}
					</TabList>

					<TabPanel>
						<ul className='flex flex-wrap'>
							{starterMenuList.map((menuItem, i) => {
								return (
									<li className='w-full md:w-1/2 px-0 md:px-5 xl:px-14 mb-9'>
										<Food
											img={menuItem.IMG}
											title={menuItem.TITLE}
											subTitle={menuItem.SUBTITLE}
											price={menuItem.PRICE}
										/>
									</li>
								);
							})}
						</ul>
					</TabPanel>
					<TabPanel>
						<ul className='flex flex-wrap'>
							{mainCourseMenuList.map((menuItem, i) => {
								return (
									<li className='w-full md:w-1/2 px-0 md:px-5 xl:px-14 mb-9'>
										<Food
											img={menuItem.IMG}
											title={menuItem.TITLE}
											subTitle={menuItem.SUBTITLE}
											price={menuItem.PRICE}
										/>
									</li>
								);
							})}
						</ul>
					</TabPanel>
					<TabPanel>
						<ul className='flex flex-wrap'>
							{breverageMenuList.map((menuItem, i) => {
								return (
									<li className='w-full md:w-1/2 px-0 md:px-5 xl:px-14 mb-9'>
										<Food
											img={menuItem.IMG}
											title={menuItem.TITLE}
											subTitle={menuItem.SUBTITLE}
											price={menuItem.PRICE}
										/>
									</li>
								);
							})}
						</ul>
					</TabPanel>
				</Tabs>

				<div className='text-center mt-4'>
					<Link
						className='btn bg-primary text-white cursor-pointer'
						to={'/menu-detail'}
					>
						View All
					</Link>
				</div>
			</div>
		</div>
	);
};

export default Starter;
