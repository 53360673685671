import React from 'react';
import Banner from '../../components/banner';
import Story from '../../components/story';
import Starter from '../../components/menu';
import SpecialMenu from '../../components/special';
import Gallery from '../../components/gallery';
import Contact from '../../components/contact';

const Home = () => {
	return (
		<>
			<div
				data-section
				id='banner'
			>
				<Banner />
			</div>

			<div
				data-section
				id='story'
			>
				<Story />
			</div>
			<div
				data-section
				id='starter'
			>
				<Starter />
			</div>
			<div
				data-section
				id='special'
			>
				<SpecialMenu />
			</div>

			<div
				data-section
				id='gallery'
			>
				<Gallery />
			</div>

			<div
				data-section
				id='contact'
			>
				<Contact />
			</div>
		</>
	);
};

export default Home;
