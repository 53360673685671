import React from 'react';

const InnerBanner = ({
	className,
	backgroundImage,
	title,
	subTitle,
	height,
}) => {
	return (
		<div
			className={`${className} 
				 relative z-0 h-screen xl:min-h-[600px] bg-no-repeat bg-cover `}
			style={{
				backgroundImage: `url(${backgroundImage})`,
				height: height,
				minHeight: height,
			}}
		>
			<div className='absolute inset-0 bg-black/[0.5]'></div>

			<div className='absolute inset-0 xl:top-33 z-10 flex flex-col items-center justify-center text-white px-5 md:px-0'>
				<h1 className='font-cursive text-2xl xl:text-4xl '>{subTitle}</h1>
				<h2 className='font-serif text-[40px] lg:text-3xl xl:text-5xl max-w-bannerTitleWidth leading-1 xl:leading-bannerTitle text-center mb-5 '>
					{title}
				</h2>
			</div>
		</div>
	);
};
export default InnerBanner;
