import React from 'react';

const Food = ({ img, title, subTitle, price }) => {
	return (
		<div className='flex lg:items-center'>
			<div>
				<img
					className='mr-8 object-cover rounded-full w-45 h-45 lg:w-90 lg:h-90 shadow-lg shadow-[#00000050]'
					src={img}
					alt=''
				/>
			</div>

			<div className='table w-full md:pl-3'>
				<div className='flex sm:block'>
					<h3 className='table-cell pr-4 w-[80%] md:w-auto whitespace-pre-wrap md:whitespace-nowrap font-serif'>
						{title}
					</h3>
					<div className='table-cell lines'></div>
					<div className='table-cell pl-4 whitespace-nowrap'>${price}</div>
				</div>
				<p className='text-grey text-sm'>{subTitle}</p>
			</div>
		</div>
	);
};

export default Food;
