import React, { useEffect } from 'react';
import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import InnerBanner from '../../components/banner/InnerBanner';
import Food from '../../components/menu/Food';
import {
	innerBannerSection,
	APPETIZER_ITEMS,
	CHICKEN_ENTREES_ITEMS,
	LAMB_ENTREES_ITEMS,
	VEGETABLE_ENTREES_ITEMS,
	SEAFOOD_ENTREES_ITEMS,
	NAAN_ENTREES_ITEMS,
	SIDES_ENTREES_ITEMS,
	DESSERTS_ENTREES_ITEMS,
	DRINKS_ENTREES_ITEMS,
} from '../../mock/mock';
import Catering from '../../components/catering';

const MenuDetail = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const collapsibleContent = [
		{
			header: 'Appetizers',
			content: (
				<ul className='flex flex-wrap md:-mx-5 xl:-mx-14'>
					{APPETIZER_ITEMS.map((menuItem, i) => {
						return (
							<li className='w-full md:w-1/2 px-0 md:px-5 xl:px-14 mb-9'>
								<Food
									img={menuItem.IMG}
									title={menuItem.TITLE}
									subTitle={menuItem.SUBTITLE}
									price={menuItem.PRICE}
								/>
							</li>
						);
					})}
				</ul>
			),
		},
		{
			header: 'Vegetables Entrees',
			content: (
				<ul className='flex flex-wrap md:-mx-5 xl:-mx-14'>
					{VEGETABLE_ENTREES_ITEMS.map((menuItem, i) => {
						return (
							<li className='w-full md:w-1/2 px-0 md:px-5 xl:px-14 mb-9'>
								<Food
									img={menuItem.IMG}
									title={menuItem.TITLE}
									subTitle={menuItem.SUBTITLE}
									price={menuItem.PRICE}
								/>
							</li>
						);
					})}
				</ul>
			),
		},
		{
			header: 'Seafood Special',
			content: (
				<ul className='flex flex-wrap md:-mx-5 xl:-mx-14'>
					{SEAFOOD_ENTREES_ITEMS.map((menuItem, i) => {
						return (
							<li className='w-full md:w-1/2 px-0 md:px-5 xl:px-14 mb-9'>
								<Food
									img={menuItem.IMG}
									title={menuItem.TITLE}
									subTitle={menuItem.SUBTITLE}
									price={menuItem.PRICE}
								/>
							</li>
						);
					})}
				</ul>
			),
		},
		{
			header: 'Chicken Entrees',
			content: (
				<ul className='flex flex-wrap md:-mx-5 xl:-mx-14'>
					{CHICKEN_ENTREES_ITEMS.map((menuItem, i) => {
						return (
							<li className='w-full md:w-1/2 px-0 md:px-5 xl:px-14 mb-9'>
								<Food
									img={menuItem.IMG}
									title={menuItem.TITLE}
									subTitle={menuItem.SUBTITLE}
									price={menuItem.PRICE}
								/>
							</li>
						);
					})}
				</ul>
			),
		},
		{
			header: 'Lamb Entrees',
			content: (
				<ul className='flex flex-wrap md:-mx-5 xl:-mx-14'>
					{LAMB_ENTREES_ITEMS.map((menuItem, i) => {
						return (
							<li className='w-full md:w-1/2 px-0 md:px-5 xl:px-14 mb-9'>
								<Food
									img={menuItem.IMG}
									title={menuItem.TITLE}
									subTitle={menuItem.SUBTITLE}
									price={menuItem.PRICE}
								/>
							</li>
						);
					})}
				</ul>
			),
		},
		{
			header: 'Naan Breads',
			content: (
				<ul className='flex flex-wrap md:-mx-5 xl:-mx-14'>
					{NAAN_ENTREES_ITEMS.map((menuItem, i) => {
						return (
							<li className='w-full md:w-1/2 px-0 md:px-5 xl:px-14 mb-9'>
								<Food
									img={menuItem.IMG}
									title={menuItem.TITLE}
									subTitle={menuItem.SUBTITLE}
									price={menuItem.PRICE}
								/>
							</li>
						);
					})}
				</ul>
			),
		},
		{
			header: 'Sides',
			content: (
				<ul className='flex flex-wrap md:-mx-5 xl:-mx-14'>
					{SIDES_ENTREES_ITEMS.map((menuItem, i) => {
						return (
							<li className='w-full md:w-1/2 px-0 md:px-5 xl:px-14 mb-9'>
								<Food
									img={menuItem.IMG}
									title={menuItem.TITLE}
									subTitle={menuItem.SUBTITLE}
									price={menuItem.PRICE}
								/>
							</li>
						);
					})}
				</ul>
			),
		},
		{
			header: 'Desserts',
			content: (
				<ul className='flex flex-wrap md:-mx-5 xl:-mx-14'>
					{DESSERTS_ENTREES_ITEMS.map((menuItem, i) => {
						return (
							<li className='w-full md:w-1/2 px-0 md:px-5 xl:px-14 mb-9'>
								<Food
									img={menuItem.IMG}
									title={menuItem.TITLE}
									subTitle={menuItem.SUBTITLE}
									price={menuItem.PRICE}
								/>
							</li>
						);
					})}
				</ul>
			),
		},
		{
			header: 'Drinks',
			content: (
				<ul className='flex flex-wrap md:-mx-5 xl:-mx-14'>
					{DRINKS_ENTREES_ITEMS.map((menuItem, i) => {
						return (
							<li className='w-full md:w-1/2 px-0 md:px-5 xl:px-14 mb-9'>
								<Food
									img={menuItem.IMG}
									title={menuItem.TITLE}
									subTitle={menuItem.SUBTITLE}
									price={menuItem.PRICE}
								/>
							</li>
						);
					})}
				</ul>
			),
		},
	];

	return (
		<>
			<InnerBanner
				className='mb-20'
				height={400}
				minHeight={400}
				title={innerBannerSection.MAIN_TITLE}
				subTitle={innerBannerSection.SUBTITLE}
				backgroundImage={innerBannerSection.BANNER_IMAGE}
			/>

			<div className='container md:w-[80%] mx-auto px-4'>
				<Accordion
					className='my-20'
					transition
					transitionTimeout={400}
				>
					<AccordionItem
						header={collapsibleContent[0].header}
						initialEntered
					>
						<div className='py-10'>{collapsibleContent[0].content}</div>
					</AccordionItem>

					<AccordionItem header={collapsibleContent[1].header}>
						<div className='py-10'>{collapsibleContent[1].content}</div>
					</AccordionItem>

					<AccordionItem header={collapsibleContent[2].header}>
						<div className='py-10'>{collapsibleContent[2].content}</div>
					</AccordionItem>

					<AccordionItem header={collapsibleContent[3].header}>
						<div className='py-10'>{collapsibleContent[3].content}</div>
					</AccordionItem>

					<AccordionItem header={collapsibleContent[4].header}>
						<div className='py-10'>{collapsibleContent[4].content}</div>
					</AccordionItem>

					<AccordionItem header={collapsibleContent[5].header}>
						<div className='py-10'>{collapsibleContent[5].content}</div>
					</AccordionItem>

					<AccordionItem header={collapsibleContent[6].header}>
						<div className='py-10'>{collapsibleContent[6].content}</div>
					</AccordionItem>
					<AccordionItem header={collapsibleContent[7].header}>
						<div className='py-10'>{collapsibleContent[7].content}</div>
					</AccordionItem>
					<AccordionItem header={collapsibleContent[8].header}>
						<div className='py-10'>{collapsibleContent[8].content}</div>
					</AccordionItem>
				</Accordion>

				<Catering />
			</div>
		</>
	);
};

export default MenuDetail;
