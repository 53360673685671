import BannerImage from '../images/IMG-20230706-WA0006.jpg';

import Image2 from '../images/IMG-20230706-WA0006.jpg';
import Image1 from '../images/IMG-20230706-WA0008.jpg';

import foodPlaceholder from '../images/food-placeholder.png';

import starterImg1 from '../images/IMG-20230706-WA0000.jpg';
import starterImg2 from '../images/IMG-20230706-WA0002.jpg';

import pakora from '../images/menu/pakora.webp';
import samosaChaat from '../images/menu/samosa-chaat.jpg';
import samosa from '../images/menu/samosa.webp';

import gingerAle from '../images/ginger-ale.jpg';
import mangoLassi from '../images/mango-lassi.jpg';
import masalaChai from '../images/masala-chai.jpg';
import Sprite from '../images/menu/Sprite.png';
import coke from '../images/menu/coke.png';
import dietCoke from '../images/menu/diet-coke.jpg';
import fanta from '../images/menu/fanta.png';
import sparklingWater from '../images/sparkling-water.jpg';

import galleryImg1 from '../images/IMG-20230706-WA0000.jpg';
import galleryImg2 from '../images/IMG-20230706-WA0001.jpg';
import galleryImg3 from '../images/IMG-20230706-WA0002.jpg';
import galleryImg4 from '../images/IMG-20230706-WA0003.jpg';
import galleryImg5 from '../images/IMG-20230706-WA0005.jpg';
import galleryImg6 from '../images/IMG-20230706-WA0006.jpg';

export const navItems = [
	'Home',
	'About Us',
	'Our Menu',
	'Special',
	'Gallery',
	'Contact us',
];

export const bannerSection = {
	BANNER_IMAGE: BannerImage,
	WELCOME_TEXT: 'Welcome to Gulab Restaurant',
	MAIN_TITLE: 'Food Filled with Love',
	SLOGAN_TEXT: 'We Serve Food, Harmony, & Love',
};

export const innerBannerSection = {
	BANNER_IMAGE: BannerImage,
	SUBTITLE: 'Our Recommended',
	MAIN_TITLE: 'Food Menu',
};

export const storySection = {
	CURSIVE_TITLE: 'Discover',
	MAIN_TITLE: 'OUR STORY',
	DESCRIPTION:
		'Gulab stands for Love and passion. Our talented chef crafts each, ensuring a delightful flavor filled with love for our guests. What truly sets us apart, however, is our unwavering commitment to personalized food and service. We strive to create lasting connections with our patrons. In this combination of amazingly flavorful North Indian Cuisine and exceptional hospitality makes Gulab Indian Restaurant a unique and memorable experience.',

	IMAGE1: Image1,
	IMAGE2: Image2,
};

export const starterSection = {
	CURSIVE_TITLE: 'Tasty & Crunchy',
	MAIN_TITLE: 'OUR STARTER',
	DESCRIPTION:
		'Each starter on our menu is carefully crafted by our talented culinary team, who artfully combine flavors and textures to create a symphony of taste. From crispy samosa to clay oven grilled chicken/lamb, our starters showcase the perfect balance of creativity and culinary expertise.',

	TABLIST: ['Starter', 'Main Course', 'Beverage'],

	STARTER_MENU_ITEMS: [
		{
			TITLE: 'Vegetable Samosa',
			SUBTITLE:
				'Triangular vegetable flour Samosa wrap stuffed with green peas, mashed potatoes and browned in heated oil',
			IMG: samosa,
			PRICE: '6.95',
		},
		{
			TITLE: 'Chicken Samosa',
			SUBTITLE:
				'Triangular flour wrap stuffed with minced chicken and browned in heated oil',
			IMG: samosa,
			PRICE: '6.95',
		},
		{
			TITLE: 'Butternut Squash Soup',
			SUBTITLE: 'Super Creamy soup made with squash',
			IMG: foodPlaceholder,
			PRICE: '9.95',
		},
		{
			TITLE: 'Seekh Kebab (lamb)',
			SUBTITLE:
				'Minced lamb wrapped around a skewer and grilled tandoor/clay oven',
			IMG: foodPlaceholder,
			PRICE: '10.95',
		},
		{
			TITLE: 'Paneer Makhani',
			SUBTITLE: 'Cottage cheese cooked with tomato sauce and butter',
			IMG: foodPlaceholder,
			PRICE: '15.95',
		},
		{
			TITLE: 'Malai Kofta',
			SUBTITLE:
				'Fried balls of paneer and mix veggies made with thick blended nut sauce',
			IMG: foodPlaceholder,
			PRICE: '15.95',
		},
		{
			TITLE: 'Malai Kofta',
			SUBTITLE:
				'Fried balls of paneer and mix veggies made with thick blended nut sauce',
			IMG: foodPlaceholder,
			PRICE: '15.95',
		},
		{
			TITLE: 'Veg Pakora',
			SUBTITLE: 'Crispy Onion fritters',
			IMG: pakora,
			PRICE: '6.95',
		},
		{
			TITLE: 'Plain Naan',
			SUBTITLE: 'Freshly clay oven baked bread',
			IMG: foodPlaceholder,
			PRICE: '3.95',
		},
		{
			TITLE: 'Paratha',
			SUBTITLE: 'Whole wheat multi layered bread',
			IMG: foodPlaceholder,
			PRICE: '4.95',
		},
	],

	MAIN_COURSE_MENU_ITEMS: [
		{
			TITLE: 'Chicken Tikka Masala',
			SUBTITLE:
				'Chicken grilled in clay oven and cooked in light creamy tomato sauce',
			IMG: foodPlaceholder,
			PRICE: '16.95',
		},
		{
			TITLE: 'Chicken Korma',
			SUBTITLE: 'Chicken cooked with flovorful almond, cashew-based sauce',
			IMG: foodPlaceholder,
			PRICE: '16.95',
		},
		{
			TITLE: 'Lamb Curry',
			SUBTITLE: 'Lamb cooked with different Indian spices',
			IMG: foodPlaceholder,
			PRICE: '17.95',
		},

		{
			TITLE: 'Lamb Vindaloo (Very Spicy)',
			SUBTITLE: 'Lamn cooked with red whole Kashmiri chili blended sauce',
			IMG: foodPlaceholder,
			PRICE: '17.95',
		},

		{
			TITLE: 'Lamb Biryani',
			SUBTITLE:
				'Tender lamb cooked with basmati rice and fresh blend of spices',
			IMG: foodPlaceholder,
			PRICE: '17.95',
		},
		{
			TITLE: 'Shrimp Tikka Masala',
			SUBTITLE: 'Shrimp cooked in light creay tomato sauce',
			IMG: foodPlaceholder,
			PRICE: '18.95',
		},
		{
			TITLE: 'Shrimp Saag',
			SUBTITLE:
				'Shrimp cooked with fresh spinach and seasoned with ginger and fresh roasted cumin',
			IMG: foodPlaceholder,
			PRICE: '18.95',
		},

		{
			TITLE: 'Shrimp Biryani',
			SUBTITLE: 'Shrimp cooked with basmati rice and fresh blend of spices',
			IMG: foodPlaceholder,
			PRICE: '18.95',
		},
	],

	BREVERAGE_MENU_ITEMS: [
		{
			TITLE: 'Ginger Ale',
			SUBTITLE: '',
			IMG: gingerAle,
			PRICE: '2.95',
		},
		{
			TITLE: 'Masala Chai (Hot)',
			SUBTITLE: '',
			IMG: masalaChai,
			PRICE: '2.95',
		},
		{
			TITLE: 'Mango Lassi',
			SUBTITLE: '',
			IMG: mangoLassi,
			PRICE: '4.95',
		},
		{
			TITLE: 'Sparkling Water',
			SUBTITLE: '',
			IMG: sparklingWater,
			PRICE: '3.95',
		},
	],
};

export const APPETIZER_ITEMS = [
	{
		TITLE: 'Vegetable Samosa',
		SUBTITLE:
			'Triangular vegetable flour Samosa wrap stuffed with green peas, mashed potatoes and browned in heated oil',
		IMG: samosa,
		PRICE: '6.95',
	},
	{
		TITLE: 'Chicken Samosa',
		SUBTITLE:
			'Triangular flour wrap stuffed with minced chicken and browned in heated oil',
		IMG: samosa,
		PRICE: '6.95',
	},
	{
		TITLE: 'Lamb Samosa',
		SUBTITLE:
			'Triangular flour wrap stuffed with minced lamb and browned in heated oil',
		IMG: samosa,
		PRICE: '7.95',
	},
	{
		TITLE: 'Samosa Chaat',
		SUBTITLE:
			'Smashed Veg samosa with chickpeas, topped with yogurt and sauces',
		IMG: samosaChaat,
		PRICE: '7.95',
	},
	{
		TITLE: 'Veg Pakora',
		SUBTITLE: 'Crispy Onion fritters',
		IMG: pakora,
		PRICE: '6.95',
	},
	{
		TITLE: 'Lentil Soup',
		SUBTITLE: 'Yellow lentil soup',
		IMG: foodPlaceholder,
		PRICE: '6.95',
	},
	{
		TITLE: 'Butternut Squash Soup',
		SUBTITLE: 'Super Creamy soup made with squash',
		IMG: foodPlaceholder,
		PRICE: '5.95',
	},
	{
		TITLE: 'Chicken Malai Kebab',
		SUBTITLE:
			'Chicken marinated in mixture of yogurt, spices and grilled in a tandoor/clay oven',
		IMG: foodPlaceholder,
		PRICE: '9.95',
	},
	{
		TITLE: 'Seekh Kebab (Lamb)',
		SUBTITLE:
			'Minced lamb wrapped around a skewer and grilled tandoor/clay oven',
		IMG: foodPlaceholder,
		PRICE: '10.95',
	},
];

export const CHICKEN_ENTREES_ITEMS = [
	{
		TITLE: 'Chicken Tikka Masala',
		SUBTITLE:
			'Chicken grilled in clay oven and cooked in light creamy tomato sauce',
		IMG: foodPlaceholder,
		PRICE: '16.95',
	},
	{
		TITLE: 'Butter Chicken',
		SUBTITLE:
			'Chicken grilled in clay oven and cooked with tomato sauce and butter',
		IMG: foodPlaceholder,
		PRICE: '16.95',
	},
	{
		TITLE: 'Chicken Curry',
		SUBTITLE: 'Homestyle chicken cooked with different Indian spices',
		IMG: foodPlaceholder,
		PRICE: '16.95',
	},
	{
		TITLE: 'Chicken Korma',
		SUBTITLE: 'Chicken cooked with flovorful almond, cashew-based sauce',
		IMG: foodPlaceholder,
		PRICE: '16.95',
	},
	{
		TITLE: 'Chicken Vindaloo (Very Spicy)',
		SUBTITLE: 'Chicken cooked with whole Kashmiri chilly blended sauce',
		IMG: foodPlaceholder,
		PRICE: '16.95',
	},
	{
		TITLE: 'Chicken Saag',
		SUBTITLE:
			'Chicken cooked with fresh spinach seasoned with ginger, garlic and fresh roasted cumin',
		IMG: foodPlaceholder,
		PRICE: '16.95',
	},
	{
		TITLE: 'Green Chilly Chicken (Spicy)',
		SUBTITLE: 'Marinated chicken made with green chilies and coriander paste',
		IMG: foodPlaceholder,
		PRICE: '16.95',
	},
	{
		TITLE: 'Chicken Tikka',
		SUBTITLE:
			'Chicken marinated in yogurt and  spices and roasted in a tandoor/clay oven',
		IMG: foodPlaceholder,
		PRICE: '16.95',
	},
	{
		TITLE: 'Chicken Biryani',
		SUBTITLE:
			'Basmati rice cooked with boneless chicken and flavored with saffron',
		IMG: foodPlaceholder,
		PRICE: '16.95',
	},
	{
		TITLE: 'Tandoori Mix Grill',
		SUBTITLE:
			'Clay oven grilled chicken tikka, chicken malai kebab, lamb seekh kebab and shrimp platter',
		IMG: foodPlaceholder,
		PRICE: '21.95',
	},
];

export const LAMB_ENTREES_ITEMS = [
	{
		TITLE: 'Lamb Tikka Masala',
		SUBTITLE: 'Lamb cooked in light creamy tomato sauce',
		IMG: foodPlaceholder,
		PRICE: '17.95',
	},
	{
		TITLE: 'Lamb Curry',
		SUBTITLE: 'Lamb cooked with different Indian spices',
		IMG: foodPlaceholder,
		PRICE: '17.95',
	},

	{
		TITLE: 'Lamb Korma',
		SUBTITLE: 'Lamb cooked with flovorful almond, cashew-based sauce',
		IMG: foodPlaceholder,
		PRICE: '17.95',
	},
	{
		TITLE: 'Lamb Vindaloo (Very Spicy)',
		SUBTITLE: 'Lamb cooked with red whole Kashmiri chilly blended sauce',
		IMG: foodPlaceholder,
		PRICE: '17.95',
	},
	{
		TITLE: 'Lamb Saag',
		SUBTITLE:
			'Lamb cooked with fresh spinach seasoned with ginger, garlic and fresh roasted cumin',
		IMG: foodPlaceholder,
		PRICE: '17.95',
	},
	{
		TITLE: 'Achari Lamb',
		SUBTITLE: 'Bold and spicy lamb curry made in Indian pickling spices',
		IMG: foodPlaceholder,
		PRICE: '17.95',
	},

	{
		TITLE: 'Lamb Biryani',
		SUBTITLE: 'Tender lamb cooked with Basmati rice and fresh blend of spices',
		IMG: foodPlaceholder,
		PRICE: '17.95',
	},
];

export const VEGETABLE_ENTREES_ITEMS = [
	{
		TITLE: 'Vegetable Tikka Masala',
		SUBTITLE: 'Mixed Veggies made with rice creamy tomoto sauce',
		IMG: foodPlaceholder,
		PRICE: '15.95',
	},
	{
		TITLE: 'Vegetable Curry (Vegan)',
		SUBTITLE: 'Mixed veggies cooked with different Indian spices',
		IMG: foodPlaceholder,
		PRICE: '15.95',
	},
	{
		TITLE: 'Aloo Gobi Mattar (Vegan)',
		SUBTITLE:
			'Light spiced curry made with potatoes, cauliflower and green peas',
		IMG: foodPlaceholder,
		PRICE: '15.95',
	},
	{
		TITLE: 'Vegetable Vindaloo (Very Spicy)',
		SUBTITLE: 'Mixed veggies cooked with red whole Kashmiri chilly based sauce',
		IMG: foodPlaceholder,
		PRICE: '15.95',
	},
	{
		TITLE: 'Vegetable Korma',
		SUBTITLE: 'Mixed veggies cooked with flovorful almond, cashew-based sauce',
		IMG: foodPlaceholder,
		PRICE: '15.95',
	},
	{
		TITLE: 'Paneer Makhani',
		SUBTITLE: 'Cottage cheese cooked with tomato sauce and butter',
		IMG: foodPlaceholder,
		PRICE: '15.95',
	},
	{
		TITLE: 'Saag Paneer',
		SUBTITLE: 'Cottage cheese with fresh spinach curry',
		IMG: foodPlaceholder,
		PRICE: '15.95',
	},
	{
		TITLE: 'Chana Masala (Vegan)',
		SUBTITLE: 'Chickpeas made with tomato-onion rice gravy',
		IMG: foodPlaceholder,
		PRICE: '15.95',
	},
	{
		TITLE: 'Chana Saag',
		SUBTITLE:
			'Chickpeas cooked with fresh spinach seasoned with ginger, garlic and fresh roasted cumin',
		IMG: foodPlaceholder,
		PRICE: '15.95',
	},
	{
		TITLE: 'Paneer Tikka Masala',
		SUBTITLE: 'Cottage cheese cooked in light creamy tomato sauce',
		IMG: foodPlaceholder,
		PRICE: '15.95',
	},
	{
		TITLE: 'Malai Kofta',
		SUBTITLE:
			'Fried balls of paneer and mix veggies made with thick blended nut sauce',
		IMG: foodPlaceholder,
		PRICE: '15.95',
	},
	{
		TITLE: 'Mattar Paneer',
		SUBTITLE:
			'Cottage cheese with green peas cooked in flavorful cashew nut sauce',
		IMG: foodPlaceholder,
		PRICE: '15.95',
	},
];

export const SEAFOOD_ENTREES_ITEMS = [
	{
		TITLE: 'Shrimp Tikka Masala',
		SUBTITLE: 'Shrimp cooked in light creamy tomato sauce',
		IMG: foodPlaceholder,
		PRICE: '18.95',
	},
	{
		TITLE: 'Shrimp Curry',
		SUBTITLE: 'Shrimp cooked with different Indian spices',
		IMG: foodPlaceholder,
		PRICE: '18.95',
	},

	{
		TITLE: 'Shrimp Korma',
		SUBTITLE: 'Shrimp cooked with flovorful almond, cashew-based sauce',
		IMG: foodPlaceholder,
		PRICE: '18.95',
	},
	{
		TITLE: 'Shrimp Vindaloo (Very Spicy)',
		SUBTITLE: 'Shrimp cooked with red whole Kashmiri chilly blended sauce',
		IMG: foodPlaceholder,
		PRICE: '18.95',
	},
	{
		TITLE: 'Shrimp Saag',
		SUBTITLE:
			'Shrimp cooked with fresh spinach seasoned with ginger and fresh roasted cumin',
		IMG: foodPlaceholder,
		PRICE: '18.95',
	},

	{
		TITLE: 'Shrimp Biryani',
		SUBTITLE: 'Shrimp cooked with Basmati rice and fresh blend of spices',
		IMG: foodPlaceholder,
		PRICE: '18.95',
	},
];

export const NAAN_ENTREES_ITEMS = [
	{
		TITLE: 'Plain Naan',
		SUBTITLE: 'Freshly clay oven baked bread',
		IMG: foodPlaceholder,
		PRICE: '3.95',
	},
	{
		TITLE: 'Garlic Naan',
		SUBTITLE: 'Naan topped with fresh garlic',
		IMG: foodPlaceholder,
		PRICE: '4.95',
	},

	{
		TITLE: 'Cilantro Naan',
		SUBTITLE: 'Naan topped with fresh cilantros',
		IMG: foodPlaceholder,
		PRICE: '4.95',
	},
	{
		TITLE: 'Cheese Naan',
		SUBTITLE: 'Naan stuffed with cheese inside',
		IMG: foodPlaceholder,
		PRICE: '4.95',
	},
	{
		TITLE: 'Coconut Naan',
		SUBTITLE: 'Naan stuffed with coconut flakes',
		IMG: foodPlaceholder,
		PRICE: '4.95',
	},
	{
		TITLE: 'Paratha',
		SUBTITLE: 'Whole wheat multi layered bread',
		IMG: foodPlaceholder,
		PRICE: '4.95',
	},
	{
		TITLE: 'Roti',
		SUBTITLE: 'Whole wheat handmade bread',
		IMG: foodPlaceholder,
		PRICE: '3.95',
	},
];

export const SIDES_ENTREES_ITEMS = [
	{
		TITLE: 'Basmati Rice',
		SUBTITLE: 'Plain steamed basmati rice',
		IMG: foodPlaceholder,
		PRICE: '2.95',
	},
	{
		TITLE: 'Dal Makhani',
		SUBTITLE: 'Whole black lentil cooked with rice flavor of butter',
		IMG: foodPlaceholder,
		PRICE: '8.95',
	},

	{
		TITLE: 'Dal Tadka',
		SUBTITLE: 'Yellow lentil',
		IMG: foodPlaceholder,
		PRICE: '8.95',
	},
	{
		TITLE: 'Mango Chutney',
		SUBTITLE: 'Sweet mango flavored sauce',
		IMG: foodPlaceholder,
		PRICE: '3.95',
	},
	{
		TITLE: 'Cilantro Chutney',
		SUBTITLE:
			'Sauce made with fresh mint leaves, green chillies and other spices',
		IMG: foodPlaceholder,
		PRICE: '3.95',
	},
	{
		TITLE: 'Papadum',
		SUBTITLE: 'Crispy lentil chips',
		IMG: foodPlaceholder,
		PRICE: '3.95',
	},
	{
		TITLE: 'Raita',
		SUBTITLE: 'Plain cool yogurt blended with carrots, cucumbers and spices',
		IMG: foodPlaceholder,
		PRICE: '3.95',
	},
	{
		TITLE: 'Tamarind Sauce',
		SUBTITLE: 'Sweet and tandy flavored sauce made with tamarind',
		IMG: foodPlaceholder,
		PRICE: '3.95',
	},
];

export const DESSERTS_ENTREES_ITEMS = [
	{
		TITLE: 'GulabJamun',
		SUBTITLE: 'Honey dumpling soaked in cardamon sugar syrup',
		IMG: foodPlaceholder,
		PRICE: '3.95',
	},
	{
		TITLE: 'Kheer',
		SUBTITLE: 'Rice pudding',
		IMG: foodPlaceholder,
		PRICE: '3.95',
	},

	{
		TITLE: 'Rasmalai',
		SUBTITLE: 'Cottage cheese soaked in thick sweetened milk',
		IMG: foodPlaceholder,
		PRICE: '4.95',
	},
];

export const DRINKS_ENTREES_ITEMS = [
	{
		TITLE: 'Sprite',
		SUBTITLE: '',
		IMG: Sprite,
		PRICE: '2.95',
	},
	{
		TITLE: 'Bottled Water',
		SUBTITLE: '',
		IMG: foodPlaceholder,
		PRICE: '2.95',
	},
	{
		TITLE: 'Bottled Iced Tea',
		SUBTITLE: '',
		IMG: foodPlaceholder,
		PRICE: '2.95',
	},
	{
		TITLE: 'Coke',
		SUBTITLE: '',
		IMG: coke,
		PRICE: '2.95',
	},
	{
		TITLE: 'Diet Coke',
		SUBTITLE: '',
		IMG: dietCoke,
		PRICE: '2.95',
	},
	{
		TITLE: 'Fanta',
		SUBTITLE: '',
		IMG: fanta,
		PRICE: '2.95',
	},
	{
		TITLE: 'Gingle Ale',
		SUBTITLE: '',
		IMG: gingerAle,
		PRICE: '2.95',
	},
	{
		TITLE: 'Mango Lassi',
		SUBTITLE: '',
		IMG: mangoLassi,
		PRICE: '4.95',
	},
	{
		TITLE: 'Masala Chai (Hot)',
		SUBTITLE: '',
		IMG: masalaChai,
		PRICE: '2.95',
	},
	{
		TITLE: 'Sparkling Water',
		SUBTITLE: '',
		IMG: sparklingWater,
		PRICE: '3.95',
	},
];

export const specialMenuSection = {
	CURSIVE_TITLE: 'We Serve the Best',
	MAIN_TITLE: 'OUR SPECIALS',
	SUBTITLE: '3 COURSE MEAL',
	PRICE: '$21.95',
	DESCRIPTION:
		'At Gulab, we take pride in presenting our esteemed patrons with an extraordinary culinary journey through our carefully curated Specials menu. These exclusive offerings are a testament to our commitment to culinary excellence and the pursuit of flavors that will leave you in awe.',

	SPECIAL_MENU_ITEMS: [
		{
			TITLE: 'Appetizer (anyone)',
			ITEMS:
				'Vegetable Samosa | Chicken Samosa | Lamb Samosa | Pakora | Lentil Soup | Butter Nut Suqash Soup',
			IMG: starterImg2,
			PRICE: 21.95,
		},
		{
			TITLE: 'Entrees (anyone)',
			SUBCATEGORIES: [
				{
					LABEL: 'Vegetables',
					ITEMS:
						'Vegetable Tikka Masala |Vegetable Curry | Aloo Gobi Matar | Vegetable Korama | Paneer Makhani | Mattar Paneer | Saag Paneer | Chana Masala | Chana Saag',
				},
				{
					LABEL: 'Chicken',
					ITEMS:
						'Chiken Tikka Masala | Butter Chicken | Chicken Curry | Chicken Korma | Chicken Vindaloo | Chicken Saag | Green Chili Chicken | Chicken Biryani',
				},
				{
					LABEL: 'Lamb',
					ITEMS:
						'Lamb Tikka Masala | Lamb Korma | Lamb Vindaloo | Lamb Saag | Achari Lamb | Lamb Biryani',
					EXTRA: '(+1.95)',
				},
				{
					LABEL: 'Shrimp',
					ITEMS:
						'Shrimp Tikka Masala | Shrimp Curry | Shrimp Korma | Shrimp Vindaloo | Shrimp Saag | Shrimp Saag | Shrimp Biryani',
					EXTRA: '(+2.95)',
				},
			],
		},

		{
			TITLE: 'Deserts or Drinks',
			ITEMS:
				'Gualab Jamun | Kheer | Rasmalai Bottled Water | Coke | Diet Coke | Sprite | Fanta | Ginget Ale | Mango Lassi(+1.95)',
			IMG: starterImg1,
			PRICE: 21.95,
			EXTRA: null,
		},
	],
};

export const GallerySection = {
	CURSIVE_TITLE: 'Our Showcase',
	MAIN_TITLE: 'GALLERY',
	DESCRIPTION:
		'Take a moment to browse through the collection of images, capturing the essence of our culinary creations. Marvel at the perfect sear on a tender lamb and chicken, the delicate balance of flavors in a beautifully composed dessert, or the artful presentation of a thoughtfully crafted vegetarian dish.',
};

export const gallerySliderImages = [
	{
		src: galleryImg1,
		alt: '',
	},
	{
		src: galleryImg2,
		alt: '',
	},
	{
		src: galleryImg3,
		alt: '',
	},
	{
		src: galleryImg4,
		alt: '',
	},
	{
		src: galleryImg5,
		alt: '',
	},
	{
		src: galleryImg6,
		alt: '',
	},
];

export const contactSection = {
	CURSIVE_TITLE: 'Need Help?',
	MAIN_TITLE: 'CONTACT US',
	DESCRIPTION:
		"We're here to assist you in any way we can. If you have any questions, feedback, or need assistance with reservations, our dedicated team is ready to help. Your satisfaction is our top priority, and we strive to provide exceptional service at every step of your dining experience.",

	LAT: 40.824602246436484,
	LNG: -73.98502669205733,
};

export const footerSection = {
	COPYRIGHT_TEXT: '© Copyright. All Rights Reserved',
};

export const contactDetails = {
	address: '629 Palaside Ave Cliffside Park NJ 07010',
	contactNo: '1 201 282-4673',
	email: 'gulabnj@gmail.com',
	lunchTime: {
		days: 'Wed to Mon',
		time: '11:30 AM - 10:00 PM',
	},
	closed: {
		days: 'Tuesdays',
	},
};

export const siteHeaders = {
	title: 'Gulab Restaurant',
};

export const CateringSection = {
	CURSIVE_TITLE: 'We Serve',
	MAIN_TITLE: 'CATERING',
	DESCRIPTION: 'All Entrees Served with Rice',
	NOTICE: '(Please Notify us 1 day prior your order)',
	NOTE: 'Our food contains dairy, nuts, eggs and shellfish. If allergy to anything, please inform our servers and staff.',
};
