import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../pages/homepage';
import MenuDetail from '../pages/menuDetail';

const Router = () => {
	return (
		<Routes>
			<Route
				path={'/'}
				element={<Home />}
			/>
			<Route
				path={'/menu-detail'}
				element={<MenuDetail />}
			/>
		</Routes>
	);
};

export default Router;
