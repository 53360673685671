import React from 'react';
import Title from '../title';
import {
	GoogleMap,
	withScriptjs,
	withGoogleMap,
	Marker,
} from 'react-google-maps';

import { contactDetails, contactSection } from '../../mock/mock';

const API_KEY = process.env.REACT_APP_GAPI_KEY;

const MapContainer = withScriptjs(
	withGoogleMap(() => (
		<GoogleMap
			defaultZoom={14}
			defaultCenter={{ lat: contactSection.LAT, lng: contactSection.LNG }}
		>
			<Marker position={{ lat: contactSection.LAT, lng: contactSection.LNG }} />
		</GoogleMap>
	))
);

const Contact = () => {
	return (
		<div
			id='contact'
			className='my-20'
		>
			<Title
				className='mb-18'
				cursiveTitle={contactSection.CURSIVE_TITLE}
				mainTitle={contactSection.MAIN_TITLE}
				description={contactSection.DESCRIPTION}
			/>

			<div className='mb-12 w-full h-[400px]'>
				<MapContainer
					googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${API_KEY}`}
					loadingElement={<div style={{ height: '100%' }} />}
					containerElement={<div style={{ height: '100%' }} />}
					mapElement={<div style={{ height: '100%' }} />}
				/>
			</div>

			<div className='container px-3 md:px-8'>
				<div className='flex flex-wrap xl:justify-center'>
					<div>
						<h3 className='text-2xl font-semibold mb-3'>Contact</h3>
						<p className='max-w-[300px]'>{contactDetails.address}</p>
						<p>
							<strong>
								Tel:{' '}
								<a href={`tel:+${contactDetails.contactNo}`}>
									+{contactDetails.contactNo}
								</a>
							</strong>
						</p>
					</div>
					<div className='mt-10 sm:mt-0 xl:ml-[245px]'>
						<h3 className='text-2xl font-semibold mb-3'>Opening Hours</h3>
						<div className='xl:flex flex-wrap'>
							<div className=''>
								<h5 className='font-bold'>Open</h5>
								<p>{contactDetails.lunchTime.days}</p>
								{contactDetails.lunchTime.time}
							</div>
							<div className='xl:ml-[120px]'>
								<h5 className='font-bold'>Closed</h5>
								<p>{contactDetails.closed.days}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Contact;
